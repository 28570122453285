/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


var $ = require('jquery');
// require('popper.js');
require('jquery-ui/ui/widgets/sortable');
require('bootstrap');

require('./app_function.js');
require('../css/app.scss');

require('../css/tooltip.scss');

console.log('Fin Webpack Encore!');


