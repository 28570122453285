/*
 * My main JavaScript file!
 *
 */

//<editor-fold desc="----------- JQuery Document-Ready">

$(document).ready(function () {
    console.log("Document ready load Dom.js!");
    // $('[data-toggle="tooltip"]').tooltip();
});


// Fin JQuery Document-Ready </editor-fold>

//<editor-fold desc="----------- Fonctions JQuery-Ui">
//
/*
$(function () {
    $(document).tooltip({
        position: {
            my: "center bottom-20",
            at: "center top",
            using: function (position, feedback) {
                $(this).css(position);
                $("<div>")
                    .addClass("arrow")
                    .addClass(feedback.vertical)
                    .addClass(feedback.horizontal)
                    .appendTo(this);
            }
        }
    });
});


$(function () {
    $("#accordion").accordion({
        header: "h3",
        collapsible: true,
        active: false,
        heightStyle: "content",
        animate: 750
    });
});*/
// Fin Fonctions JQuery-Ui </editor-fold>

//<editor-fold desc="----------- Fonctions Div">


// function n(n) renvoi une valeur  avec un 0 prefixant les valeurs < 9
(function ($) {
    $.fn.n = function (n) {
        return n > 9 ? "" + n : "0" + n;
    }
})(jQuery);

// function getHeure(date) renvoi une heure formatee hh:mm:ss
(function ($) {
    $.fn.getHeure = function (laDate) {
        var datFormate = $().n(laDate.getHours()) + ":";
        datFormate += $().n(laDate.getMinutes()) + ":";
        datFormate += $().n(laDate.getSeconds());
        return datFormate;
    };
})(jQuery);

// function getDateFr(date) renvoi une date en Fr :
//      -   complete (lundi 1 Janvier 2018)
//      -   ou abrége  (Lun. 1 Jan.)
(function ($) {
    $.fn.getDateFr = function (laDate, setFullOuAbr) {
        if (setFullOuAbr) {
            // DateHeure  format fr complet
            var jourFull = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
            var moisFull = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
            return jourFull[laDate.getDay()] + " " + laDate.getDate() + " " + moisFull[laDate.getMonth()] + " " + laDate.getFullYear();
        } else {
            // DateHeure  format fr abrégé
            var jour = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
            var mois = ["Jan", "Fév", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"];
            var annee = laDate.getFullYear() + "";
            var anneeAbr = annee.substring(2);
            return jour[laDate.getDay()] + ". " + laDate.getDate() + " " + mois[laDate.getMonth()];
        }
    };
})(jQuery);

// function setInterval affiche  tte les 1000ms la date/heure
$(function () {
    setInterval(function () {
        var laDate = new Date;
        var horloge_date = $().getDateFr(laDate, false);
        var horloge_time = $().getHeure(laDate);

        $("#horloge").text(horloge_date + " " + horloge_time);
    }, 1000);
});

(function ($) {
    $(document).ready(function () {
        $('#myMenuVertical > ul > li > a').click(function () {
            $('#myMenuVertical li').removeClass('active');
            $(this).closest('li').addClass('active');
            var checkElement = $(this).next();
            if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                $(this).closest('li').removeClass('active');
                checkElement.slideUp('normal');
            }
            if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                $('#myMenuVertical ul ul:visible').slideUp('normal');
                checkElement.slideDown('normal');
            }
            return $(this).closest('li').find('ul').children().length == 0;
        });
    });
})(jQuery);
// Fin Fonctions Div </editor-fold>
